<template>
    <footer class="subfooter">
      <div class="container">
        <h4>
          <strong class="subfooter__heading">Foooooter</strong>
          <br />
          <strong class="subfooter__heading">librarianu</strong>
        </h4>
        <p class="subfooter__paragraph">
          Vyhľadávanie kníh v knižniciach nikdy <br />
          nebolo jednoduchšie. Vytvorené <br />
          (budúcim bc.) Jakubom Rončakom.
        </p>
      </div>
    </footer>
</template>
